#preview-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
#preview-modal .preview-box {
  width: 900px;
  height: 80%;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
}
#preview-modal .publish-time {
  font-size:14px;
  color:#999999;
  letter-spacing:-0.2px;
  line-height: 25px;
  margin: 0;
}
#preview-modal .publish-time span:first-child {
  margin-right: 20px;
  color: rgb(192, 57, 43);
}
#preview-modal .publish-time .origin {
  color: rgb(97, 169, 81);
}
#preview-modal .line-dashed {
  height: 1px;
  border-top: 1px dashed #979797;
  margin-top: 15px;
}
#preview-modal .preview-content {
  flex: 1;
  overflow-y: auto;
}
#preview-modal .preview-content img {
  max-width: 100%;
}
#preview-modal .preview-btm {
  text-align: center;
}