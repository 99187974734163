#login{
    width:100%;
    position: relative;
}
#login .content{
    position: absolute;
    width:565px;
    height:547px;
    margin:auto;
    top: 0;
    left: 0;
    bottom: 0; 
    right: 0; 
}
#login .tolog{
    background:#fafafa;
    border-radius:8px;
    width:564px;
    height:437px;
    margin-top:53px;
    padding:43px 50px;
    box-sizing: border-box;
    font-family:PingFangSC-Medium;
    font-size:24px;
    color:#ffffff;
    letter-spacing:-0.27px;
    text-align:left;
}
#login .font-cn{
    font-family:PingFangSC-Medium;
    font-size:26px;
    color:#ffffff;
    letter-spacing:-0.31px;
    text-align:left;
    width:100%;
    line-height:1;
}
#login .font-en{
    font-family:PingFangSC-Light;
    font-size:16px;
    color:#ffffff;
    letter-spacing:-0.18px;
    text-align:left;
    width:100%;
    line-height:1;
}
#login .header{
    height:57px;;
    width:565px;
    display:flex;
    justify-content: space-around;
    margin-bottom:53px;
}
#login .logoname{
    width:334px;
}
#login .input1{
    font-family:PingFangSC-Light;
    font-size:18px;
    color:#333333;
    letter-spacing:-0.2px;
    text-align:left;
}
#login .logoinput{
    background:#ffffff;
    border:1px solid #dcdcdc;
    border-radius:8px;
    width:462px;
    height:58px;
    color:#000;
    padding-left:15px;
    box-sizing: border-box;
}
.btnlogin{
    background:#2a8ff6;
    border-radius:8px;
    width:464px;
    height:60px;
    border:none;
    outline:none;
    cursor: pointer;
}