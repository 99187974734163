.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:before {
  left: 0;
}
.clearfixTest{
  position: relative;
  width:105px;
}
.clearfixTest:after{
  content:" （图片最佳展示尺寸为800*440）"!important;
  position: absolute;
  right:-300px;
  top: 43px;
  width:300px;
  height:30px;
}
.bf-container{
  border:1px solid #ccc;
}
.App .uload-attachment{
  text-align: left;
  padding: 20px 0 ;
}
.App .uload-attachment input {
  display: none;
}
.App .uload-attachment label {
  display: block;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  background: #1890ff;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.App .uload-attachment p{
  text-indent: 2em;
  margin: 0;
  line-height: 35px;
} 
.App .uload-attachment button {
  margin-top: 20px;
}
.App .uload-attachment p i:last-child, .App .show-attachment p i:last-child{
  cursor: pointer;
}
.App .show-attachment {
  text-align: left;
}
.App .show-attachment p i {
  margin-left: 20px;
}
#div-input {
    font-feature-settings: "tnum";
    font-variant: tabular-nums;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-upload.ant-upload-select {
  display: block!important;
}