.register_header{
    height:60px;
    width:100%;
    color:#fff;
    background:#05274D;
    font-size:19px;
    line-height:60px;
    margin-bottom:12px;
}
.register_header>div{
    width:270px;
    height:100%;
    float:left;
    text-align:center;
    cursor: pointer;
}
.reg_button1{
    background:#2A8FF6;
}
.reg_statistics{
    height:54px;
    width:100%;
    line-height:60px;
}
.reg_statistics_num{
    float:left;
}
.reg_statistics_issue{
    float:right;
    /* margin-top:14px; */
    width:140px;
    height:54px !important;
    background:#E50012 !important;
    color:#fff !important;
    font-size:19px !important;
}
#register {
    margin-left: 0;
}
#register .ant-input-group .ant-select-arrow .ant-select-arrow-icon, #tables .ant-input-group .ant-input-search-icon {
    color: #004894;
    font-size: 18px;
  }
  #register .label{
    height:40px;
    line-height:40px;
    margin-right:14px;
    font-size:16px;
    color:#333333;
    font-weight: 600;
  }
  #register .ant-input-group  .ant-select-selection-selected-value{
    font-size:16px;
    color:#004894;
  }
  #register .ant-input-group  .ant-select-selection--single{
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-color: #004894 !important;
    border-right: none !important;
  }
  #register .ant-input-group  .ant-select-lg.selectBox.ant-select.ant-select-enabled:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 22px;
    background: #d2d2d2;
    right: 0;
    top: 9px;
  }
  #register .ant-input-group  .ant-input{
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important; 
    border-color: #004894 !important;
    border-left: none !important;
    outline: none !important;
  }
  #register   .ant-input-group.ant-input-group-compact {
      width:  auto;
      float: right;
  }
  #register   .ant-input-group.ant-input-group-compact > span:not(:last-child) > .ant-input{
    border-right-width: 1px !important;
  }
  #register .ant-input-group  .buttonBox{
    background:#004894;
    border-radius:100px;
    width:99px;
    height:40px;
    font-size:16px;
    color:#ffffff;
    text-align:left;
    margin-left:20px;
  }
  #register .ant-spin-container {
      width: 100%;
  }