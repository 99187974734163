body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header{
    
}
.header div:nth-of-type(1){
    width:200px;
    float:left;
    text-align:left;
    font-size:19px;
    color:#333333;
    font-weight: bold;
    margin-left:30px;
}
.header div:nth-of-type(2) span{
    font-size:16px;
    color:#333333;
    cursor: pointer;
}
.header div:nth-of-type(3){
    font-size:16px;
    color:#9a9a9a;
}
.header div{
    width:200px;
    float:right;
    text-align:right;
}
.ant-menu-submenu-title a{
   color: hsla(0,0%,100%,.65) !important;
   text-decoration: none !important;
}
.trigger{
    position: absolute;
    top:25px;
    cursor: pointer;
}
.ant-layout-sider{
    height:100 !important;
}
.ant-switch {
    width: 80px;
}
.info{
    margin-top:12px;
    height:46px;
    border:1px solid #4ca0f4;
    line-height:46px;
    background-color:#e5f7ff;
}
.info>span{
    margin-left:12px;
}
.addNumber{
    color:#4ca0f4
}
.clearAll{
    color:#4ca0f4;
    cursor: pointer;
}
.ant-spin-nested-loading{
    /* position: absolute !important; */
}
#example .col-sql{
    overflow:hidden;
    text-overflow:ellipsis; 
    white-space: nowrap;
    display:block;
    width:200px;
}
#example {
    margin-left: 0;
}
#example .ant-input-group .ant-select-arrow .ant-select-arrow-icon, #tables .ant-input-group .ant-input-search-icon {
    color: #004894;
    font-size: 18px;
  }
  #example .label{
    height:40px;
    line-height:40px;
    margin-right:14px;
    font-size:16px;
    color:#333333;
    font-weight: 600;
  }
  #example .ant-input-group  .ant-select-selection-selected-value{
    font-size:16px;
    color:#004894;
  }
  #example .ant-input-group  .ant-select-selection--single{
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-color: #004894 !important;
    border-right: none !important;
  }
  #example .ant-input-group  .ant-select-lg.selectBox.ant-select.ant-select-enabled:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 22px;
    background: #d2d2d2;
    right: 0;
    top: 9px;
  }
  #example .ant-input-group  .ant-input{
    border-radius: 20px !important;
    border-color: #004894 !important;
    outline: none !important;
  }
  #example   .ant-input-group.ant-input-group-compact {
      width:  auto;
      float: right;
  }
  #example   .ant-input-group.ant-input-group-compact > span:not(:last-child) > .ant-input{
    border-right-width: 1px !important;
  }
  #example .ant-input-group  .buttonBox{
    background:#004894;
    border-radius:100px;
    width:99px;
    height:40px;
    font-size:16px;
    color:#ffffff;
    text-align:left;
    margin-left:20px;
  }
  #example .ant-spin-container {
      width: 100%;
  }
#recruitment .col-sql{
    overflow:hidden;
    text-overflow:ellipsis; 
    white-space: nowrap;
    display:block;
    width:200px;
}
.register_header{
    height:60px;
    width:100%;
    color:#fff;
    background:#05274D;
    font-size:19px;
    line-height:60px;
    margin-bottom:12px;
}
.register_header>div{
    width:270px;
    height:100%;
    float:left;
    text-align:center;
    cursor: pointer;
}
.reg_button1{
    background:#2A8FF6;
}
.reg_statistics{
    height:54px;
    width:100%;
    line-height:60px;
}
.reg_statistics_num{
    float:left;
}
.reg_statistics_issue{
    float:right;
    /* margin-top:14px; */
    width:140px;
    height:54px !important;
    background:#E50012 !important;
    color:#fff !important;
    font-size:19px !important;
}
#register {
    margin-left: 0;
}
#register .ant-input-group .ant-select-arrow .ant-select-arrow-icon, #tables .ant-input-group .ant-input-search-icon {
    color: #004894;
    font-size: 18px;
  }
  #register .label{
    height:40px;
    line-height:40px;
    margin-right:14px;
    font-size:16px;
    color:#333333;
    font-weight: 600;
  }
  #register .ant-input-group  .ant-select-selection-selected-value{
    font-size:16px;
    color:#004894;
  }
  #register .ant-input-group  .ant-select-selection--single{
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-color: #004894 !important;
    border-right: none !important;
  }
  #register .ant-input-group  .ant-select-lg.selectBox.ant-select.ant-select-enabled:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 22px;
    background: #d2d2d2;
    right: 0;
    top: 9px;
  }
  #register .ant-input-group  .ant-input{
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important; 
    border-color: #004894 !important;
    border-left: none !important;
    outline: none !important;
  }
  #register   .ant-input-group.ant-input-group-compact {
      width:  auto;
      float: right;
  }
  #register   .ant-input-group.ant-input-group-compact > span:not(:last-child) > .ant-input{
    border-right-width: 1px !important;
  }
  #register .ant-input-group  .buttonBox{
    background:#004894;
    border-radius:100px;
    width:99px;
    height:40px;
    font-size:16px;
    color:#ffffff;
    text-align:left;
    margin-left:20px;
  }
  #register .ant-spin-container {
      width: 100%;
  }
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:before {
  left: 0;
}
.clearfixTest{
  position: relative;
  width:105px;
}
.clearfixTest:after{
  content:" \FF08\56FE\7247\6700\4F73\5C55\793A\5C3A\5BF8\4E3A  800*440\FF09"!important;
  position: absolute;
  right:-300px;
  top: 43px;
  width:300px;
  height:30px;
}
.bf-container{
  border:1px solid #ccc;
}
.App .uload-attachment{
  text-align: left;
  padding: 20px 0 ;
}
.App .uload-attachment input {
  display: none;
}
.App .uload-attachment label {
  display: block;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  background: #1890ff;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.App .uload-attachment p{
  text-indent: 2em;
  margin: 0;
  line-height: 35px;
} 
.App .uload-attachment button {
  margin-top: 20px;
}
.App .uload-attachment p i:last-child, .App .show-attachment p i:last-child{
  cursor: pointer;
}
.App .show-attachment {
  text-align: left;
}
.App .show-attachment p i {
  margin-left: 20px;
}
#div-input {
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-upload.ant-upload-select {
  display: block!important;
}
#preview-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
#preview-modal .preview-box {
  width: 900px;
  height: 80%;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  background: #fff;
}
#preview-modal .publish-time {
  font-size:14px;
  color:#999999;
  letter-spacing:-0.2px;
  line-height: 25px;
  margin: 0;
}
#preview-modal .publish-time span:first-child {
  margin-right: 20px;
  color: rgb(192, 57, 43);
}
#preview-modal .publish-time .origin {
  color: rgb(97, 169, 81);
}
#preview-modal .line-dashed {
  height: 1px;
  border-top: 1px dashed #979797;
  margin-top: 15px;
}
#preview-modal .preview-content {
  flex: 1 1;
  overflow-y: auto;
}
#preview-modal .preview-content img {
  max-width: 100%;
}
#preview-modal .preview-btm {
  text-align: center;
}


#recruitmentedit .ant-input-number ,#ruarua {
    margin-bottom:12px;
}
#feedback .col-sql{
    overflow:hidden;
    text-overflow:ellipsis; 
    white-space: nowrap;
    display:block;
    width:200px;
}
#organization {
  margin-left: 0;
}
#organization .ant-input-group .ant-select-arrow .ant-select-arrow-icon, #tables .ant-input-group .ant-input-search-icon {
  color: #004894;
  font-size: 18px;
}
#organization .label{
  height:40px;
  line-height:40px;
  margin-right:14px;
  font-size:16px;
  color:#333333;
  font-weight: 600;
}
#organization .ant-input-group  .ant-select-selection-selected-value{
  font-size:16px;
  color:#004894;
}
#organization .ant-input-group  .ant-select-selection--single{
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-color: #004894 !important;
  border-right: none !important;
}
#organization .ant-input-group  .ant-select-lg.selectBox.ant-select.ant-select-enabled:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 22px;
  background: #d2d2d2;
  right: 0;
  top: 9px;
}
#organization .ant-input-group  .ant-input{
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important; 
  border-color: #004894 !important;
  border-left: none !important;
  outline: none !important;
}
#organization   .ant-input-group.ant-input-group-compact {
    width:  auto;
    float: right;
}
#organization   .ant-input-group.ant-input-group-compact > span:not(:last-child) > .ant-input{
  border-right-width: 1px !important;
}
#organization .ant-input-group  .buttonBox{
  background:#004894;
  border-radius:100px;
  width:99px;
  height:40px;
  font-size:16px;
  color:#ffffff;
  text-align:left;
  margin-left:20px;
}
#organization .ant-spin-container {
    width: 100%;
}


#banner-setting button {
  width: 144px;
  height: 50px;
  font-size: 16px;
  margin-bottom: 35px;
}
#banner-setting .delete {
  position: relative;
  margin-left: 36px;
  cursor: pointer;
  color: #2a8ff6;
}
#banner-setting .delete::before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: -18px;
  display: block;
  width: 1px;
  height: 25px;
  background: #2a8ff6;
}
#banner-setting .ant-table-thead > tr:first-child > th:last-child,.ant-table-tbody > tr > td:last-child {
  text-align: center;
}
#add-banner {
  padding-bottom: 40px;
  background: #fff;
  overflow: hidden;
}
#add-banner button {
  width: 166px;
  height: 50px;
  margin-right: 20px;
  margin-top: 50px;
}
#add-banner > span {
  display: block;
  overflow: hidden;
}
#login{
    width:100%;
    position: relative;
}
#login .content{
    position: absolute;
    width:565px;
    height:547px;
    margin:auto;
    top: 0;
    left: 0;
    bottom: 0; 
    right: 0; 
}
#login .tolog{
    background:#fafafa;
    border-radius:8px;
    width:564px;
    height:437px;
    margin-top:53px;
    padding:43px 50px;
    box-sizing: border-box;
    font-family:PingFangSC-Medium;
    font-size:24px;
    color:#ffffff;
    letter-spacing:-0.27px;
    text-align:left;
}
#login .font-cn{
    font-family:PingFangSC-Medium;
    font-size:26px;
    color:#ffffff;
    letter-spacing:-0.31px;
    text-align:left;
    width:100%;
    line-height:1;
}
#login .font-en{
    font-family:PingFangSC-Light;
    font-size:16px;
    color:#ffffff;
    letter-spacing:-0.18px;
    text-align:left;
    width:100%;
    line-height:1;
}
#login .header{
    height:57px;;
    width:565px;
    display:flex;
    justify-content: space-around;
    margin-bottom:53px;
}
#login .logoname{
    width:334px;
}
#login .input1{
    font-family:PingFangSC-Light;
    font-size:18px;
    color:#333333;
    letter-spacing:-0.2px;
    text-align:left;
}
#login .logoinput{
    background:#ffffff;
    border:1px solid #dcdcdc;
    border-radius:8px;
    width:462px;
    height:58px;
    color:#000;
    padding-left:15px;
    box-sizing: border-box;
}
.btnlogin{
    background:#2a8ff6;
    border-radius:8px;
    width:464px;
    height:60px;
    border:none;
    outline:none;
    cursor: pointer;
}
/* .App {
  text-align: center;
} */

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

