
#banner-setting button {
  width: 144px;
  height: 50px;
  font-size: 16px;
  margin-bottom: 35px;
}
#banner-setting .delete {
  position: relative;
  margin-left: 36px;
  cursor: pointer;
  color: #2a8ff6;
}
#banner-setting .delete::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -18px;
  display: block;
  width: 1px;
  height: 25px;
  background: #2a8ff6;
}
#banner-setting .ant-table-thead > tr:first-child > th:last-child,.ant-table-tbody > tr > td:last-child {
  text-align: center;
}