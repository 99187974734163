.info{
    margin-top:12px;
    height:46px;
    border:1px solid #4ca0f4;
    line-height:46px;
    background-color:#e5f7ff;
}
.info>span{
    margin-left:12px;
}
.addNumber{
    color:#4ca0f4
}
.clearAll{
    color:#4ca0f4;
    cursor: pointer;
}
.ant-spin-nested-loading{
    /* position: absolute !important; */
}
#example .col-sql{
    overflow:hidden;
    text-overflow:ellipsis; 
    white-space: nowrap;
    display:block;
    width:200px;
}
#example {
    margin-left: 0;
}
#example .ant-input-group .ant-select-arrow .ant-select-arrow-icon, #tables .ant-input-group .ant-input-search-icon {
    color: #004894;
    font-size: 18px;
  }
  #example .label{
    height:40px;
    line-height:40px;
    margin-right:14px;
    font-size:16px;
    color:#333333;
    font-weight: 600;
  }
  #example .ant-input-group  .ant-select-selection-selected-value{
    font-size:16px;
    color:#004894;
  }
  #example .ant-input-group  .ant-select-selection--single{
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-color: #004894 !important;
    border-right: none !important;
  }
  #example .ant-input-group  .ant-select-lg.selectBox.ant-select.ant-select-enabled:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 22px;
    background: #d2d2d2;
    right: 0;
    top: 9px;
  }
  #example .ant-input-group  .ant-input{
    border-radius: 20px !important;
    border-color: #004894 !important;
    outline: none !important;
  }
  #example   .ant-input-group.ant-input-group-compact {
      width:  auto;
      float: right;
  }
  #example   .ant-input-group.ant-input-group-compact > span:not(:last-child) > .ant-input{
    border-right-width: 1px !important;
  }
  #example .ant-input-group  .buttonBox{
    background:#004894;
    border-radius:100px;
    width:99px;
    height:40px;
    font-size:16px;
    color:#ffffff;
    text-align:left;
    margin-left:20px;
  }
  #example .ant-spin-container {
      width: 100%;
  }