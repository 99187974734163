.header{
    
}
.header div:nth-of-type(1){
    width:200px;
    float:left;
    text-align:left;
    font-size:19px;
    color:#333333;
    font-weight: bold;
    margin-left:30px;
}
.header div:nth-of-type(2) span{
    font-size:16px;
    color:#333333;
    cursor: pointer;
}
.header div:nth-of-type(3){
    font-size:16px;
    color:#9a9a9a;
}
.header div{
    width:200px;
    float:right;
    text-align:right;
}
.ant-menu-submenu-title a{
   color: hsla(0,0%,100%,.65) !important;
   text-decoration: none !important;
}
.trigger{
    position: absolute;
    top:25px;
    cursor: pointer;
}
.ant-layout-sider{
    height:100 !important;
}
.ant-switch {
    width: 80px;
}