#add-banner {
  padding-bottom: 40px;
  background: #fff;
  overflow: hidden;
}
#add-banner button {
  width: 166px;
  height: 50px;
  margin-right: 20px;
  margin-top: 50px;
}
#add-banner > span {
  display: block;
  overflow: hidden;
}